export const errorHandler = (toast?: any, error?: any) => {
  const message = error?.response?.data?.message;
  if (toast) {
    toast?.({
      title: "Error - something went wrong",
      description: message ?? "Please try again later",
      variant: "destructive",
    });
  }
  console.error(error);
};
