import { FC, ReactNode } from "react";
import { Button } from "../v2/Button";
import {
  Dialog as OriginalDialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../v2/AltDialog";
import Spinner from "../spinner";

interface DialogProps {
  trigger?: ReactNode;
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  title?: string;
  description?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
  infoDialog?: boolean;
  className?: string;
}

const Dialog: FC<DialogProps> = ({
  trigger,
  open,
  onClose,
  onConfirm,
  title,
  description,
  cancelText = "Cancel",
  confirmText = "Yes",
  loading,
  infoDialog,
  className
}) => {
  return (
    <OriginalDialog open={open}>
      {trigger && <DialogTrigger>{trigger}</DialogTrigger>}
      <DialogContent className={`${className} py-4`}>
        <DialogTitle className="text-center text-base font-bold text-[#000D3D]">
          {title}
        </DialogTitle>
        <DialogDescription>{description}</DialogDescription>
        <div className="flex justify-stretch gap-6 ">
          {infoDialog ? (
            <DialogClose asChild>
              <Button className="w-full rounded-xl" onClick={onClose}>
                Ok
              </Button>
            </DialogClose>
          ) : (
            <>
              <DialogClose asChild>
                <Button
                  className="w-full rounded-xl border-primary"
                  variant="outline"
                  onClick={onClose}
                >
                  {cancelText}
                </Button>
              </DialogClose>
              <Button
                className="w-full rounded-xl"
                onClick={onConfirm}
                disabled={loading}
              >
                {loading ? <Spinner /> : confirmText}
              </Button>
            </>
          )}
        </div>
      </DialogContent>
    </OriginalDialog>
  );
};

export default Dialog;
